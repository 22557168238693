import React, { useState, useEffect, useId } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, updateDoc, getDocs, getDoc } from "firebase/firestore";
import '../Styles/Auth/TeacherRoll.css'
import { FaTrash } from "react-icons/fa";
import { db } from "../Components/Firebase";
import Loading from "../Components/Loading";

function TeacherRoll() {
    const [gradeLevels, setGradeLevels] = useState([""]);
    const [specializations, setSpecializations] = useState([""]);
    const [roll, setRoll] = useState("");
    const { userid } = useParams();
    const [loading, setLoading] = useState(false);
    const [schoolName, setSchoolName] = useState("");
    const nav = useNavigate();

    const [fetchedLayers, setFetchedLayers] = useState([]);
    const [fetchedSpecializations, setFetchedSpecializations] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                
                const userRef = doc(db, "Teachers", userid);
                const userSnap = await getDoc(userRef);
                if (userSnap.exists()) {
                    setSchoolName(userSnap.data().schoolName);
                    console.log(userid)
                } else {
                    console.log(useId + schoolName)
                    console.log("No such user!");
                }

                const layersSnapshot = await getDocs(db.collection("Schools").doc(schoolName).collection("Layers"));
                const layersData = layersSnapshot.docs.map(doc => doc.data().name);
                setFetchedLayers(layersData);

                const specializationsSnapshot = await getDocs(db.collection("Schools").doc(schoolName).collection("Specializations"));
                const specializationsData = specializationsSnapshot.docs.map(doc => doc.data().name);
                setFetchedSpecializations(specializationsData);
                setLoading(false)
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        fetchData();
    }, [userid, schoolName, gradeLevels, specializations]);

    const handleSpecializationChange = (index, value) => {
        const newSpecializations = [...specializations];
        newSpecializations[index] = value;
        setSpecializations(newSpecializations);
    };

    const addSpecialization = () => {
        if (specializations.length < 5) {
            setSpecializations([...specializations, ""]);
        }
    };

    const removeSpecialization = (index) => {
        if (specializations.length > 1) {
            const newSpecializations = specializations.filter((_, i) => i !== index);
            setSpecializations(newSpecializations);
        }
    };

    const handleGradeLevelChange = (index, value) => {
        const newGradeLevels = [...gradeLevels];
        newGradeLevels[index] = value;
        setGradeLevels(newGradeLevels);
    };

    const addGradeLevel = () => {
        if (gradeLevels.length < 5) {
            setGradeLevels([...gradeLevels, ""]);
        }
    };

    const removeGradeLevel = (index) => {
        if (gradeLevels.length > 1) {
            const newGradeLevels = gradeLevels.filter((_, i) => i !== index);
            setGradeLevels(newGradeLevels);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const userData = { roll };

        if (roll !== "Secretary" && roll !== "Manager") {
            userData.gradeLevels = gradeLevels;
            userData.specializations = specializations;
        }

        try {
            const userRef = doc(db, "Teachers", userid);
            await updateDoc(userRef, userData);
            nav(`/CheckUser/${userid}`);
        } catch (error) {
            console.error("Error updating user profile: ", error);
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <Loading />;

    return (
        <React.Fragment>
            <form className="teacher-roll-form" dir="rtl" onSubmit={handleSubmit}>
                <h1>מה התפקיד שלך</h1>

                <div className="form-group">
                    <label htmlFor="roll">תפקיד:</label>
                    <select
                        id="roll"
                        value={roll}
                        onChange={(e) => setRoll(e.target.value)}
                        required
                    >
                        <option value="">בחר תפקיד</option>
                        <option value="Manager">מנהל</option>
                        <option value="Teacher">מורה</option>
                        <option value="Secretary">מזכיר</option>
                    </select>
                </div>

                {roll !== "Secretary" && roll !== "Manager" && (
                    <>
                        <div className="form-group">
                            <label>שכבות:</label>
                            {gradeLevels.map((gradeLevel, index) => (
                                <div key={index} className="grade-level-input">
                                    <select
                                        value={gradeLevel}
                                        onChange={(e) => handleGradeLevelChange(index, e.target.value)}
                                        required
                                    >
                                        <option value="">בחר שכבה</option>
                                        {fetchedLayers.map((layer, i) => (
                                            <option key={i} value={layer}>{layer}</option>
                                        ))}
                                    </select>
                                    {gradeLevels.length > 1 && (
                                        <button type="button" onClick={() => removeGradeLevel(index)} className="remove-grade-level">
                                            <FaTrash />
                                        </button>
                                    )}
                                </div>
                            ))}
                            {gradeLevels.length < 5 && (
                                <button type="button" onClick={addGradeLevel} className="add-grade-level">
                                    הוסף שכבה
                                </button>
                            )}
                        </div>

                        <div className="form-group">
                            <label>התמחויות:</label>
                            {specializations.map((specialization, index) => (
                                <div key={index} className="specialization-input">
                                    <select
                                        value={specialization}
                                        onChange={(e) => handleSpecializationChange(index, e.target.value)}
                                        required
                                    >
                                        <option value="">בחר התמחות</option>
                                        {fetchedSpecializations.map((specialization, i) => (
                                            <option key={i} value={specialization}>{specialization}</option>
                                        ))}
                                    </select>
                                    {specializations.length > 1 && (
                                        <button type="button" onClick={() => removeSpecialization(index)} className="remove-specialization">
                                            <FaTrash />
                                        </button>
                                    )}
                                </div>
                            ))}
                            {specializations.length < 5 && (
                                <button type="button" onClick={addSpecialization} className="add-specialization">
                                    הוסף התמחות
                                </button>
                            )}
                        </div>
                    </>
                )}

                <button type="submit" className="submit-button">הבא</button>
            </form>
        </React.Fragment>
    );
}

export default TeacherRoll;
