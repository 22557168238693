import React from "react";

import '../Styles/Components/Loading.css'

function Loading () {

    return (

        <div className="Loading">

        <svg xmlns="http://www.w3.org/2000/svg" width="73" viewBox="0 0 73 86" fill="none">
          <path 
            d="M3.82152 3H36.6967M69.5718 3H36.6967M36.6967 3V55.6394M3.82145 30.6306C-6.06133 93.9592 76.6309 106.665 69.5718 30.6306" 
            stroke="white" 
            strokeWidth="5" 
            strokeLinecap="round"
          />
        </svg>
            
        </div>
    )
}

export default Loading