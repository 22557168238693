import React, { useState } from "react";
import '../Styles/Auth/CreateAccount.css';
import { FaCamera } from 'react-icons/fa';
import { auth, db, storage } from "../Components/Firebase";
import Loading from "../Components/Loading";
import { useNavigate } from "react-router-dom";

function CreateAccount() {
    const [userImage, setUserImage] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const navigate = useNavigate();

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setUserImage(file);
        }
    };

    const translateErrorToHebrew = (error) => {
        switch (error.code) {
            case "auth/email-already-in-use":
                return "כתובת דוא״ל זו כבר בשימוש.";
            case "auth/invalid-email":
                return "כתובת דוא״ל לא תקינה.";
            case "auth/weak-password":
                return "הסיסמה חלשה מדי. בחר סיסמה חזקה יותר.";
            default:
                return "אירעה שגיאה בעת ההרשמה.";
        }
    };

    const handleSignUp = async () => {
        setLoading(true);
        try {
            const userCredential = await auth.createUserWithEmailAndPassword(email, password);
            const user = userCredential.user;

            let imageUrl = "";
            if (userImage) {
                const imageRef = storage.ref(`Teachers/Userimage/${user.uid}/profile.jpg`);
                await imageRef.put(userImage);
                imageUrl = await imageRef.getDownloadURL();
            }

            await db.collection("Teachers").doc(user.uid).set({
                firstName: firstName,
                lastName: lastName,
                email: email,
                imageUrl: imageUrl,
                createdAt: new Date(),
            });

            await user.sendEmailVerification();

            navigate('/VerifyEmail');
        } catch (error) {
            setError(translateErrorToHebrew(error));
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <Loading />;

    return (
        <React.Fragment>
            <div className='Auth-Head2'>
                <svg xmlns="http://www.w3.org/2000/svg" width="73" viewBox="0 0 73 86" fill="none">
                    <path 
                        d="M3.82152 3H36.6967M69.5718 3H36.6967M36.6967 3V55.6394M3.82145 30.6306C-6.06133 93.9592 76.6309 106.665 69.5718 30.6306" 
                        stroke="white" 
                        strokeWidth="5" 
                        strokeLinecap="round"
                    />
                </svg>
                <h1>רישום</h1>
            </div>

            <div className='Create-box'>
                <h1>צור חשבון</h1>
                <p>אנא מלא את המידע שלך</p>

                <div className='inputs2'>
                    <div className='image-upload-container'>
                        <label htmlFor='imageInput' className='image-upload-label'>
                            {userImage ? (
                                <img
                                    src={URL.createObjectURL(userImage)}
                                    alt='Profile Preview'
                                    className='profile-preview'
                                />
                            ) : (
                                <FaCamera className='camera-icon' />
                            )}
                        </label>
                        <input
                            type='file'
                            id='imageInput'
                            accept='image/*'
                            style={{ display: 'none' }}
                            onChange={handleImageChange}
                        />
                    </div>

                    <input 
                        type='text' 
                        placeholder='שם פרטי' 
                        value={firstName} 
                        onChange={(e) => setFirstName(e.target.value)}
                    />

                    <input 
                        type='text' 
                        placeholder='שם משפחה' 
                        value={lastName} 
                        onChange={(e) => setLastName(e.target.value)}
                    />

                    <input 
                        type='email' 
                        placeholder='דוא"ל' 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <input 
                        type='password' 
                        placeholder='סיסמה' 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)}
                    />

                    {error && <p className='error'>{error}</p>}

                    <button onClick={handleSignUp}>רישום</button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default CreateAccount;