import React, { useEffect, useState } from "react";
import { auth } from "../Components/Firebase";
import { useNavigate } from "react-router-dom";
import '../Styles/Auth/VerifyEmail.css';

function VerifyEmail() {
    const [isVerified, setIsVerified] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    const checkEmailVerification = async () => {
        const user = auth.currentUser;
        await user.reload();
        if (user.emailVerified) {
            setIsVerified(true);
            navigate(`/SchoolCode/${user.uid}`);
        } else {
            setErrorMessage("הדוא\"ל שלך לא אומת. אנא אמת את חשבונך.");
        }
    };

    useEffect(() => {
        const interval = setInterval(checkEmailVerification, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <React.Fragment>
            <div className="VerifyEmail">
                <p>שלחנו לך אימייל לאימות</p>

                <svg xmlns="http://www.w3.org/2000/svg" width="73" viewBox="0 0 73 86" fill="none">
                    <path 
                        d="M3.82152 3H36.6967M69.5718 3H36.6967M36.6967 3V55.6394M3.82145 30.6306C-6.06133 93.9592 76.6309 106.665 69.5718 30.6306" 
                        stroke="white" 
                        strokeWidth="5" 
                        strokeLinecap="round"
                    />
                </svg>

                {isVerified ? (
                    <p style={{ textAlign: 'center', color: '#fff' }}>האימייל אומת בהצלחה.</p>
                ) : (
                    <>
                        {showMessage && (
                            <p style={{ textAlign: 'center', color: '#fff', fontSize: '20px', width: '90%' }}>
                                אנא בדוק את תיבת הדואר שלך לאימות הדוא"ל
                            </p>
                        )}
                        {errorMessage && (
                            <p style={{ textAlign: 'center', color: '#fff', fontSize: '18px', width: '90%' }}>
                                {errorMessage}
                            </p>
                        )}
                    </>
                )}
            </div>
        </React.Fragment>
    );
}

export default VerifyEmail;
