import React, { useState, useEffect } from 'react';
import '../Styles/Auth/Login.css';
import { auth } from '../Components/Firebase';
import { useNavigate } from 'react-router-dom';
import Loading from '../Components/Loading';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const [loading, setLoading] = useState (false)

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        navigate('/home');
      } else {
        setLoading(false); 
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleLogin = async () => {
    setLoading(true)
    try {
      await auth.signInWithEmailAndPassword(email, password);
      navigate('/dashboard');
      setLoading(false)
    } catch (err) {
      setError('התחברות נכשלה, בדוק את הדואר האלקטרוני או הסיסמה ונסה שוב.');
      setLoading(false)
    }
  };

  const handleRegister = async () => {
    
    navigate('/CreateAccount')
  };

  if (loading) return <Loading/>

  return (
    <React.Fragment>
      <div className='Auth-Head'>
        <svg xmlns="http://www.w3.org/2000/svg" width="73" viewBox="0 0 73 86" fill="none">
          <path 
            d="M3.82152 3H36.6967M69.5718 3H36.6967M36.6967 3V55.6394M3.82145 30.6306C-6.06133 93.9592 76.6309 106.665 69.5718 30.6306" 
            stroke="white" 
            strokeWidth="5" 
            strokeLinecap="round"
          />
        </svg>

        <h1>לחשבון שלי</h1>
      </div>

      <div className='Login-box'>
        <h1>!ברוך הבא</h1>
        <p>אנא התחבר לחשבונך</p>

        <div className='inputs'>
          <input 
            type='email' 
            placeholder='דוא"ל' 
            value={email} 
            onChange={(e) => setEmail(e.target.value)}
          />
          <input 
            type='password' 
            placeholder='סיסמה' 
            value={password} 
            onChange={(e) => setPassword(e.target.value)}
          />

          {error && <p className='error'>{error}</p>}

          <button onClick={handleLogin}>התחברות</button>
          <button 
            style={{ background: 'rgb(254, 79, 90)' }} 
            onClick={handleRegister}
          >
            רישום
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;