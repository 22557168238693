import { Route, Routes } from "react-router-dom";

//Pages
import Loading from "./Components/Loading";
import Login from "./Auth/Login";
import CreateAccount from "./Auth/CreateAccount";
import VerifyEmail from "./Auth/VerifyEmail";
import SchoolCode from "./Auth/SchoolCode";
import TeacherRoll from "./Auth/TeacherRoll";

function App () {

  return (

    <Routes>

      <Route path="/" element={<Login/>}/>
      <Route path="/CreateAccount" element={<CreateAccount/>}/>
      <Route path="/VerifyEmail" element={<VerifyEmail/>}/>
      <Route path="/SchoolCode/:userid" element={<SchoolCode/>}/>
      <Route path="/SchoolCode/:userid/TeacherRoll" element={<TeacherRoll/>}/>
      <Route path="/Loading" element={<Loading/>}/>

    </Routes>
  )
}

export default App